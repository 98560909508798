/* eslint-disable sonarjs/cognitive-complexity, no-unused-expressions, react/forbid-component-props */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import reactCookies from 'react-cookies';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import COOKIES from '../../../../config/cookies/cookies';
import { LINK_CHECKOUT_BUTTON } from '../../../../config/links/links';
import { BUTTON_RESERVE_NOW, DEPOSITS } from '../../../../config/text/text';
import { trackEvent } from '../../../../helpers/analytics';
import {
  basketCreator,
  mergeProducts,
} from '../../../../helpers/basketCreator';
import { formatCurrency } from '../../../../helpers/currency';
import {
  getIsChooseYourOptionsDeal,
  isBookingCalendarChooseDateDeal,
  isDepositDeal,
} from '../../../../helpers/deals';
import errorTrackingService from '../../../../helpers/errorTrackingService';
import { isWowcherBrand } from '../../../../helpers/pages';
import { isMobileViewport } from '../../../../helpers/screen';
import {
  mainDealLayoutType,
  getScrollElementDealButtonType,
} from '../../../../helpers/scroll';
import { redirectToURL } from '../../../../helpers/url';
import { getIsEligibleForVip } from '../../../../helpers/vipSubscribeHelper';
import ThemeContext from '../../../../providers/ThemeProvider';
import { createBasket, fetchBasket } from '../../../../redux/actions/basket';
import { replaceBasket } from '../../../../services/basket';
import PrimaryButton from '../../../_generic/button/PrimaryButton';

const ReserveButton = ({
  deal = {},
  extraClasses,
  style = {},
  buttonRef = undefined,
  onClickAction,
  isOptionSelected = false,
}) => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const locationStore = useSelector((state) => state.locations);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userVipStatus = useSelector(
    (state) => state.user.userprofile.userVipStatus,
  );

  const addToBasket = async () => {
    const basketToken = reactCookies.load(COOKIES.basketToken);
    const customerToken = reactCookies.load(COOKIES.customerToken);
    const currentBasket = await fetchBasket(
      basketToken,
      customerToken,
      isAuthenticated,
    );

    const products = basketCreator(
      deal,
      locationStore,
      0,
      false,
      true, // deposit deal
    );

    let productsAux = products;

    if (currentBasket && currentBasket.products) {
      // if there were products before, we merge them
      productsAux = mergeProducts(currentBasket.products, products);
    }

    const isExistingBasket =
      currentBasket &&
      currentBasket.products &&
      basketToken &&
      basketToken?.length > 10;
    const basketTokenAux = isExistingBasket
      ? await replaceBasket(
          basketToken,
          productsAux,
          isAuthenticated,
          getIsEligibleForVip(isAuthenticated, userVipStatus),
        )
      : await createBasket(
          productsAux,
          getIsEligibleForVip(isAuthenticated, userVipStatus),
        );

    if (!basketTokenAux && !basketToken) {
      errorTrackingService.logError({
        error: 'No Basket Token',
        message: 'Basket token cannot be undefined.',
      });
    }

    basketToken || basketTokenAux
      ? redirectToURL(
          `${LINK_CHECKOUT_BUTTON}/${basketToken || basketTokenAux}`,
          router,
        )
      : redirectToURL(`${LINK_CHECKOUT_BUTTON}`, router);
  };

  const defaultOnClickHandler = () => {
    // default is mainly used for the deal page
    if (
      getIsChooseYourOptionsDeal(deal) ||
      isBookingCalendarChooseDateDeal(deal)
    ) {
      if (!isMobileViewport()) {
        if (isOptionSelected) {
          buttonRef?.current?.click();

          return;
        }

        scroller.scrollTo(
          getScrollElementDealButtonType(deal, mainDealLayoutType.actions),
          {
            delay: 100,
            duration: 1_000,
            smooth: true,
          },
        );
      } else if (isMobileViewport()) {
        buttonRef?.current?.click();
      }
    } else {
      addToBasket();
    }
  };

  const onClick = async () => {
    trackEvent('click_buy');
    if (onClickAction) {
      onClickAction();

      return;
    }

    defaultOnClickHandler();
  };

  const baseStyles = {
    ...style,
    backgroundColor: theme.colors.successgreen,
    border: `1px solid ${theme.colors.sucessgreenborder}`,
    color: '#000',
  };

  if (!isDepositDeal(deal)) {
    return null;
  }

  return (
    <>
      <PrimaryButton
        aria-label={BUTTON_RESERVE_NOW}
        className={extraClasses}
        onClick={() => onClick()}
        style={baseStyles}
      >
        {isMobileViewport() ? DEPOSITS : BUTTON_RESERVE_NOW}
        {deal?.depositPrice > 0 && <>FROM </>}
        {formatCurrency(deal.currency, deal?.depositPrice)}
      </PrimaryButton>
      <style jsx>{`
        @media (max-width: ${theme.breakpoints.mdDown}) {
          :global(.primary-button.main.reserve-button) {
            border-radius: 5px;
            padding: 5px;
            position: absolute;
            right: 2px;
            text-transform: ${isWowcherBrand() ? 'capitalize' : 'lowercase'};
            top: 42px;
          }
        }
      `}</style>
    </>
  );
};

ReserveButton.propTypes = {
  buttonRef: PropTypes.any,
  deal: PropTypes.object,
  extraClasses: PropTypes.string,
  isOptionSelected: PropTypes.bool,
  onClickAction: PropTypes.func,
  style: PropTypes.object,
};

export default ReserveButton;
