/* eslint-disable @next/next/no-img-element, sonarjs/cognitive-complexity */
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LINK_HOME } from '../../config/links/links';
import ThemeContext from '../../providers/ThemeProvider';

const HeaderLogo = ({ reversed, width, height, align, both, top = false }) => {
  const theme = useContext(ThemeContext);
  const [imgSource, setImgSource] = useState(theme.images.headerlogo);
  const logoSource = reversed
    ? theme.images.headerLogoReversed
    : theme.images.headerlogo;
  const isVipUser = useSelector((state) => state.user.userprofile.vipUser);
  const vipLogoSource = theme?.images?.vipheaderlogo;

  useEffect(() => {
    if (logoSource) {
      if (isVipUser) {
        setImgSource(vipLogoSource);
      } else {
        setImgSource(logoSource);
      }
    }
  }, [logoSource, isVipUser, vipLogoSource]);

  let heightAux = height;
  let widthAux = 'auto';
  if (height && height === 'auto') {
    heightAux = '100%';
    widthAux = '100%';
  }

  return (
    <>
      <div
        className={`logo-container ${both ? 'both' : ''} ${top ? 'top' : ''}`}
      >
        <Link href={top ? '' : LINK_HOME}>
          <a className="logo-link">
            {!both && (
              <img
                alt="Logo"
                className="logo"
                height={`${theme.images.headerlogodefheight}`}
                src={`${imgSource}`}
                width={`${theme.images.headerlogodefwidth}`}
              />
            )}
            {both && (
              <>
                <img
                  alt="Logo"
                  className="logo headerlogo"
                  height={`${theme.images.headerlogodefheight}`}
                  src={`${theme.images.headerlogo}`}
                  width={`${theme.images.headerlogodefwidth}`}
                />
                <img
                  alt="Logo"
                  className="logo headerlogoreversed"
                  height={`${theme.images.headerlogodefheight}`}
                  src={`${theme.images.headerLogoReversed}`}
                  width={`${theme.images.headerlogodefwidth}`}
                />
              </>
            )}
          </a>
        </Link>
      </div>
      <style jsx>{`
        .logo-container {
          display: flex;
          min-height: 41px;
          align-items: center;
          justify-content: ${align || 'center'};
          max-height: ${height || '26px'};
          max-width: ${width || '80px'};
        }
        .logo-link {
          // display: flex;
        }
        .logo {
          display: flex;
          height: ${heightAux || '26px'};
          width: ${widthAux};
          align-self: flex-start;
        }
        .top {
          position: absolute;
          top: 50px;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .logo-container {
            display: flex;
            min-height: 75px;
            max-height: ${theme.images.headerlogomdwidth};
            max-width: ${theme.images.headerlogomdwidth};
          }
          .logo {
            display: block;
            height: auto;
            width: ${theme.images.headerlogomdwidth};
          }
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .logo-container {
            max-width: ${theme.images.headerlogolgwidth};
            max-height: ${theme.images.headerlogolgwidth};
          }
          .logo {
            width: ${theme.images.headerlogolgwidth};
            height: auto;
          }
        }
        .both .headerlogo {
          display: none;
        }
        .both .headerlogoreversed {
          display: block;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          //768
          .both .headerlogo {
            display: block;
          }
          .both .headerlogoreversed {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

HeaderLogo.propTypes = {
  align: PropTypes.string,
  both: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reversed: PropTypes.bool,
  top: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

HeaderLogo.defaultProps = {
  both: false,
  top: false,
};

export default HeaderLogo;
